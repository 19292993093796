<template>
  <CyHeader
    v-if="catalogRepository"
    :loading="loading">
    <template #title>
      {{ catalogRepository.name }}
    </template>
    <template #actions>
      <portal-target name="header-actions"/>
    </template>
    <template #metadata>
      <CyHeaderMetadata
        v-if="catalogRepository.owner"
        :label="displayName(catalogRepository.owner)">
        <CyAvatar
          xs
          :item="catalogRepository.owner"/>
      </CyHeaderMetadata>
      <CyHeaderMetadata
        v-if="catalogRepository.created_at"
        :label="getCreatedLabel(catalogRepository)"/>
      <CyHeaderMetadata
        v-if="catalogRepository.updated_at"
        :label="getUpdatedLabel(catalogRepository)"/>
      <CyHeaderMetadata
        v-if="catalogRepository.refreshed_at"
        :label="$t('time.refreshed', [$date.$formatTimeAgo(catalogRepository.refreshed_at)])"/>
    </template>
  </CyHeader>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import CyHeader from '@/components/CyHeader.vue'
import CyHeaderMetadata from '@/components/CyHeaderMetadata.vue'
import { displayName, getCreatedLabel, getUpdatedLabel } from '@/utils/helpers'

export default {
  name: 'CyHeaderVariantCatalogRepository',
  components: {
    CyHeader,
    CyHeaderMetadata,
  },
  computed: {
    ...mapState('organization/catalogRepository', {
      loading: (state) => state.fetchInProgress.catalogRepository,
    }),
    ...mapGetters('organization/catalogRepository', [
      'catalogRepository',
    ]),
  },
  methods: {
    displayName,
    getCreatedLabel,
    getUpdatedLabel,
  },
}
</script>
